import { useEffect, useState } from "react";

import ReplayIcon from '@mui/icons-material/Replay';
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";

import BarGraph from "components/BarGraph";
import CenterFlexBox from "components/CenterFlexBox";
import CustomButton from "components/CustomButton";
import Select from "components/Select";
import ShadowBox from "components/ShadowBox";
import { PurpleSwitch } from "components/Switch";
import Toggle from "components/Toggle";
import { Link } from "react-router-dom";
import { getBatches, getTemplates } from "services";
import { Session } from "utils";
import RecentPerformance from "./RecentPerformance";
import TemplateAnalyticsCard from "./TemplateAnalyticsCard";
import { TourSteps, useTour } from "contexts";

const defaultDatasets = [
    {
        id: 'attemptRate', data: [],
        backgroundColor: 'rgba(0, 122, 255, 1)', label: 'Attempt Rate',
    },
    { id: 'average', data: [], label: 'Average' },
];

const PageSize = 6;

const defaultLabels = ['', '', '', '', '', '', '', '', '', ''];

const Legends = [
    { id: 'attemptRate', label: 'Attempt Rate', color: 'rgba(0, 122, 255, 1)' },
    {
        id: 'average', label: 'Average Score',
        color: 'rgba(88, 86, 214, 1)', Switch: PurpleSwitch,
    },
];

const ToggleOptions = [
    { id: 'assessment', label: 'Assessment' },
    { id: 'batch', label: 'Batch' }
];

const AssessmentOptions = [
    { _id: "all", name: "All" },
    { _id: "assignment", name: "Assignment" },
    { _id: "practice", name: "Practice" },
    { _id: "follow_up", name: "Follow up" },
];

export default function OverallPerformance() {
    const { state: tourState } = useTour();
    const [filter, setFilter] = useState('');
    const [datasets, setDatasets] = useState(defaultDatasets);
    const [labels, setLabels] = useState(defaultLabels);
    const [templatesName, setTemplatesName] = useState([]);
    const [section, setSection] = useState('assessment');
    const [batches, setBatches] = useState([]);
    const [allTemplates, setAllTemplates] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);

    const GraphOptions = {
        responsive: true,
        maintainAspectRatio: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            title: {
                display: false,
                text: 'Performance',
            },
            tooltip: {
                callbacks: {
                    title: ([item]) => {
                        return `Assessment: ${templatesName[item.dataIndex]}`;
                    },
                    labelTextColor: () => 'black'
                },
                backgroundColor: 'ghostwhite',
                titleColor: 'black'
            }
        },
        scales: { yAxis: { min: 0, max: 100, } }
    };

    const loadTemplates = (pageNumber) => {
        getTemplates({
            user: Session.userId,
            pageNumber,
            pageSize: PageSize,
            orderBy: 'createdAt',
            order: "desc",
            include: ['batch'],
            includeTotal: true,
        })
            .then(({ interviewTemplates, total }) => {
                if (interviewTemplates.length) {
                    interviewTemplates.length >= total && setShowLoadMore(false);
                    setAllTemplates(existingTemplates => [
                        ...existingTemplates, ...interviewTemplates
                    ]);
                } else setShowLoadMore(false);
            }).catch((error) => console.error(error))
    };

    useEffect(() => loadTemplates(pageNumber), [pageNumber]);

    useEffect(() => {
        getBatches({ client: Session.userId })
            .then(({ batches }) => { setBatches(batches); })
            .catch((error) => { console.error(error); })
    }, []);

    useEffect(() => {
        if (!tourState) {
            if (section === 'batch') {
                getTemplates({
                    user: Session.userId,
                    batch: filter,
                    pageSize: PageSize,
                    orderBy: 'createdAt',
                    order: "desc",
                    include: ['batch']
                }).then(({ interviewTemplates }) => {
                    const performanceWeightage = Session.getUser()?.metadata?.performanceWeightage;

                    const speechWeightage = performanceWeightage?.communication || 50;
                    const contentWeightage = performanceWeightage?.content || 50;

                    const studentAverageData = [];
                    const attemptRateData = [];
                    const averageBarColor = [];
                    const newTemplatesName = [];

                    const newLabels = interviewTemplates.reverse()
                        .map((template, index) => {
                            const { batch, communicationAverage, contentAverage, attemptedBy } = template;

                            newTemplatesName.push(template.name.concat(` (${template.type})`));
                            attemptRateData.push(
                                Math.round((attemptedBy.length / batch.studentCount) * 100)
                            );
                            studentAverageData.push(
                                Math.round(
                                    (
                                        (communicationAverage * speechWeightage) +
                                        (contentAverage * contentWeightage)
                                    ) / 100
                                )
                            );
                            averageBarColor.push('rgba(88, 86, 214, 1)');


                            return (template.name.length > 6) ?
                                `${template.name.slice(0, 6)}...` :
                                template.name;
                        });

                    for (let i = newLabels.length; i < 12; i++) newLabels.push('');

                    const newDatasets = [...datasets];
                    newDatasets[0].data = attemptRateData;
                    newDatasets[1].data = studentAverageData;
                    newDatasets[1].backgroundColor = averageBarColor;

                    setTemplatesName(newTemplatesName);
                    setLabels(newLabels);
                    setDatasets(newDatasets);
                }).catch(error => { console.error(error); })
            } else {
                if (filter === 'all') setTemplates(allTemplates);
                else setTemplates(allTemplates.filter(t => t.type === filter));
            }
        }
    }, [filter, allTemplates, batches, section, tourState]);

    useEffect(() => {
        if (tourState) {
            const showDummyTemplates = allTemplates.length === 0;

            if (showDummyTemplates) {
                setTemplates(TourSteps[0].data.templates);
            }

            return () => {
                if (showDummyTemplates) setTemplates([]);
            };
        }
    }, [tourState])

    useEffect(() => {
        if (section === 'assessment') setFilter('all');
        else setFilter(batches[0]?._id || '')
    }, [section, batches]);

    const handleFilterChange = (e) => setFilter(e.target.value);

    const handleToggleChange = (option) => {
        setSection(option.id);
    };

    const loadMoreTemplates = () => setPageNumber((pageNumber) => pageNumber + 1);

    return (
        <ShadowBox>
            <Typography variant="h5" fontWeight={600} >
                Performance Analytics
            </Typography>
            <Box
                display="flex"
                justifyContent="space-between"
                py={1}
                alignItems="center"
            >
                <Box>
                    <Box display="flex" gap={2} alignItems="center" my={2}>
                        <Typography
                            variant="h6"
                            fontWeight={700}
                            fontSize={14}
                            color='#000000CC'
                        >
                            View By
                        </Typography>
                        <Toggle
                            id="welcome-to-languify-tip"
                            onChange={handleToggleChange}
                            br={1}
                            width={250}
                            height={32}
                            TypographyProps={{
                                textTransform: "uppercase", fontWeight: 600
                            }}
                            options={ToggleOptions}
                        />
                    </Box>
                    {section === 'batch' && (
                        <Link
                            to={filter ? `/batches/${filter}/performance` : "/batches/create"}
                            style={{ color: "#02569D", fontSize: 14 }}
                        >
                            {filter ? "View Detailed Analysis for this batch" : "Create a Batch"}
                        </Link>
                    )}
                </Box>

                {section === 'assessment' ? (
                    <Select
                        label="Assessment Type"
                        options={AssessmentOptions}
                        value={filter}
                        onChange={handleFilterChange}
                    />
                ) : (
                    <Select
                        label="Select Batch"
                        options={batches}
                        value={filter}
                        disabled={batches.length === 0}
                        onChange={handleFilterChange}
                    />
                )}
            </Box>
            <Collapse in={section === "assessment"} >
                <Box 
                  overflow={"auto"} maxHeight={400} 
                  display='flex' flexDirection='column'
                >
                  <Box display='flex' flexWrap='wrap' gap='14px'>
                    {templates.map((assessment, i) => (
                        <TemplateAnalyticsCard key={i} {...assessment} />
                    ))}
                  </Box>
                    {allTemplates.length === 0 && (
                        <CenterFlexBox height={"100%"} column>
                            <Typography>
                                No assessments created yet!!
                            </Typography>
                            <Link
                                to={"/assessments/create"}
                                style={{ color: "#02569D", fontSize: 14 }}
                            >
                                Create Assessment
                            </Link>
                        </CenterFlexBox>
                    )}
                    {showLoadMore && (
                        <CustomButton
                            onClick={loadMoreTemplates}
                            sx={{ mx: 'auto', display: "flex", mt: 2 }}
                            size="small"
                            startIcon={<ReplayIcon />}
                        >
                            Load more
                        </CustomButton>
                    )}
                </Box>
            </Collapse>

            <Collapse in={section === "batch"} >
                <CenterFlexBox alignItems="stretch">
                    <BarGraph
                        labels={labels}
                        datasets={datasets}
                        legends={Legends}
                        xLabel='Assessments'
                        aspectRatio={3}
                        graphOptions={GraphOptions}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <RecentPerformance batch={section === "batch" && filter} />
                </CenterFlexBox>
            </Collapse>
        </ShadowBox >
    );
}